import * as React from 'react';

import { HiOutlineDesktopComputer, HiOutlineDocumentSearch, HiOutlineHome } from 'react-icons/hi';
import { NavLinkItem } from 'typings';
import { MdOutlineDriveFolderUpload, MdOutlineHelpCenter } from 'react-icons/md';
import { TbArchive, TbReportSearch } from 'react-icons/tb';
import { BgColorsOutlined, LaptopOutlined } from '@ant-design/icons';
import { BiUserPin } from 'react-icons/bi';

export const sections: NavLinkItem[] = [
  {
    key: 'home',
    route: '/landing',
    label: 'pages.landing',
    description: 'Home',
    icon: <HiOutlineHome />
  },
  {
    key: 'dashboard',
    route: '/dashboard',
    label: 'pages.dashboard',
    description: 'pages.dashboardDescription',
    icon: <HiOutlineDesktopComputer />
  },
  {
    key: 'upload',
    route: '/upload',
    label: 'pages.upload',
    description: 'pages.uploadDescription',
    icon: <MdOutlineDriveFolderUpload />
  },
  {
    key: 'claims',
    route: '',
    label: 'pages.documentStatus',
    description: 'pages.documentStatusDescription',
    icon: <HiOutlineDocumentSearch />
    //color: '#4da5b8'
  },
  {
    key: 'profile',
    route: '/profile',
    label: 'pages.profile',
    description: 'pages.profileDescription',
    icon: <BiUserPin />
  },
  {
    key: 'reports',
    route: '',
    label: 'pages.reports',
    description: 'pages.reportsDescription',
    icon: <TbReportSearch />
  },
  {
    key: 'documentStatusReports',
    route: '/document-status-reports',
    label: 'pages.documentStatusReports',
    description: 'pages.reportsDescription',
    icon: <TbReportSearch />,
    parentId: 'reports'
  },
  {
    key: 'userProfileReports',
    route: '/user-profile-reports',
    label: 'pages.userProfileReports',
    description: 'pages.reportsDescription',
    icon: <TbReportSearch />,
    parentId: 'reports'
  },
  {
    key: 'geographyVolumeReports',
    route: '/geography-volume-reports',
    label: 'pages.geographyVolumeReports',
    description: 'pages.geographyVolumeReportsDescription',
    icon: <TbReportSearch />,
    parentId: 'reports'
  },
  {
    key: 'help',
    route: '/help-option',
    label: 'pages.help',
    description: 'pages.helpDescription',
    icon: <MdOutlineHelpCenter />
  },
  // {
  //   key: 'hcfa',
  //   route: '/hcfa',
  //   label: 'pages.hcfa',
  //   description: 'hcfa:description',
  //   icon: <MdOutlineHealthAndSafety />,
  //   parentId: 'claims'
  // },
  // {
  //   key: 'ub04',
  //   route: '/ub04',
  //   label: 'pages.ub04',
  //   description: 'ub04:description',
  //   icon: <TbHealthRecognition />,
  //   parentId: 'claims'
  // },
  // {
  //   key: 'dental',
  //   route: '/dental',
  //   label: 'pages.dental',
  //   description: 'dental:description',
  //   icon: <TbDental />,
  //   parentId: 'claims'
  // },
  {
    key: 'archive',
    route: '/archive',
    label: 'pages.archive',
    description: 'archive:description',
    icon: <TbArchive />,
    parentId: 'claims'
  },
  {
    key: 'adminPanel',
    route: '/admin',
    label: 'pages.admin',
    description: 'pages.adminDescription',
    icon: <LaptopOutlined />
  },
  {
    key: 'branding',
    route: '/admin/branding',
    label: 'pages.branding',
    description: 'pages.brandingDescription',
    icon: <BgColorsOutlined />,
    parentId: 'adminPanel'
  }
  // {
  //   key: 'otherDocuments',
  //   route: '',
  //   label: 'pages.otherDocuments',
  //   description: 'pages.otherDocumentsDescription',
  //   icon: <HiOutlineDocumentDuplicate />
  // },
  // {
  //   key: 'priorAuth',
  //   route: '/other-documents',
  //   label: 'pages.priorAuthorization',
  //   description: 'pages.priorAuthorizationDescription',
  //   icon: <AuthorizationIcon />,
  //   parentId: 'otherDocuments'
  // },
  // {
  //   key: 'appeals',
  //   route: '/other-documents',
  //   label: 'pages.appeals',
  //   description: 'pages.appealsDescription',
  //   icon: <AppealsIcon />,
  //   parentId: 'otherDocuments'
  // },
  // {
  //   key: 'payorInfo',
  //   route: '/other-documents',
  //   label: 'pages.payorInfo',
  //   description: 'pages.payorInfoDescription',
  //   icon: <InfoIcon />,
  //   parentId: 'otherDocuments'
  // },
  // {
  //   key: 'transaction',
  //   route: '/other-documents',
  //   label: 'pages.transactionHistory',
  //   description: 'pages.transactionHistoryDescription',
  //   icon: <TransactionIcon />,
  //   parentId: 'otherDocuments'
  // },
];
