import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Button, Divider, List, Modal, Typography, notification } from 'antd';
import defaultColorsJson from '../styles/defaultColors.json';
import { getAllBrands } from 'api/branding';
import React, { useEffect, useState } from 'react';
import { useBrandingContext } from 'components/common/BrandingProvider';
import ColorPalette from './Branding/ColorPalette';
import { STATE_OPTIONS } from './Branding/Branding';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';
import { deleteBrandById } from 'api/claims';

const { confirm } = Modal;

function AllBrands() {
  const { accessToken } = useOidcAccessToken();
  const { allBrands, setAllBrands } = useBrandingContext();
  const { isAdmin } = useUserData();
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  useEffect(() => {
    getBrandingFunction();
  }, []);

  function getState(value: string) {
    return STATE_OPTIONS.find((o) => o.value === value)?.label || '-';
  }

  const getBrandingFunction = async () => {
    if (accessToken) {
      try {
        const data = await getAllBrands(accessToken);
        if (data) {
          setAllBrands(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: <div>No brands added!</div>
        });
      } finally {
        //
      }
    }
  };

  const handleDelete = (brandId: string, brandTitle: string) => {
    confirm({
      title: 'Delete?',
      content: `Are you sure you want to delete brand ${brandTitle} [${brandId}]?`,
      okButtonProps: { loading: deleteInProgress },
      onOk: async () => {
        await deleteClaim(brandId);
      },
      okText: 'Delete'
    });
  };

  const deleteClaim = async (brandId: string) => {
    setDeleteInProgress(true);
    try {
      await deleteBrandById(brandId, accessToken);
      notification.success({ message: 'Brand deleted' });
      const brands: any = allBrands;
      const filteredBrands = brands?.filter((d: any) => d.id !== brandId);
      setAllBrands(filteredBrands);
    } catch (e) {
      notification.error({ message: 'Claim delete failed', description: 'Server error occurred' });
    } finally {
      setDeleteInProgress(false);
    }
  };

  if (!isAdmin) return <NotAllowedPage />;
  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">ALL {(allBrands as unknown as any[])?.length} BRANDS</Divider>
        </div>
        <div className="box all-brands-list">
          {allBrands &&
            (allBrands as unknown as any[])?.map((item: any, i: number) => {
              const colorObject = item?.colorPalette ? JSON.parse(item?.colorPalette) : defaultColorsJson;
              return (
                <div className="brand" key={i}>
                  <div>
                    <Typography.Text mark>[{i + 1}]</Typography.Text> Title: {item.title ? item.title : '-'}{' '}
                    <span>
                      <small style={{ opacity: 0.45 }}>[{item?.id}]</small>
                    </span>
                    <p style={{ marginBottom: 0 }}>
                      State: {item.state ? getState(item.state) : '-'} <br />
                      LOB: {item.lob ? item.lob : '-'}{' '}
                    </p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      Color Palette:{' '}
                      {colorObject ? (
                        // <div className="color-palette" style={{ marginTop: '7px' }}>
                        //   <span style={{ backgroundColor: `${colorObject['primary-color']}` }}></span>
                        //   <span style={{ backgroundColor: `${colorObject['neutral-link-color']}` }}></span>
                        //   <span style={{ backgroundColor: `${colorObject['primary-link-color']}` }}></span>
                        //   <span style={{ backgroundColor: `${colorObject['selected-text-color']}` }}></span>
                        //   <span style={{ backgroundColor: `${colorObject['regular-text-color']}` }}></span>
                        // </div>
                        <ColorPalette colorObject={colorObject} />
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div>
                    <Button danger type="default" onClick={() => handleDelete(item.id, item.title)}>
                      Delete brand
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AllBrands;
